/* eslint-disable complexity */
import React, { useEffect, useState } from 'react'

import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { FrankieButton, FrankieDivider, FrankieLoader } from 'frankify/src'

import { useOrganisationSettingsQuery } from 'features/organisation-settings'

import { AddressCategoryTypes } from 'entities/applicant'
import { CountryAlpha3CodeTypes } from 'entities/country'
import {
  ConsentType,
  getWorkflowStatusKey,
  ServiceProfileState,
  useEntityDataQuery,
  useFrankie2R2Customer,
  WorkflowStatusKeysTypes,
} from 'entities/entity'
import { mainNavPaths, useSetOriginatedPath } from 'entities/routing'
import { useHasFeatureFlag } from 'entities/session'
import { useWorkflowListQuery } from 'entities/workflow'

import { SelectFormField, TextAreaFormField } from 'shared/form'
import { I18nKeys, useI18n } from 'shared/i18n'
import { TrackingEventsTypes, trackingManager } from 'shared/tracking'
import { useValidationRules } from 'shared/validation'

import { INDIVIDUAL_PROFILE_F2_KEY } from '../../individual-profile-f2.key'
import { individualProfileF2En } from '../../locale/individual-profile-f2.en'
import {
  IAttachment,
  IIndividualProfileF2FormType,
} from '../../model/individual-profile-f2-form.model'
import { useCreateIndividual } from '../../mutation/create-individual.mutation'
import { useUpdateIndividual } from '../../mutation/update-individual.mutation'
import { individualProfileF2Qa } from '../../qa/individual-profile-f2.qa'
import { useGetIndividualFormData } from '../../state/getIndividualForm.data'
import { AddressForms } from '../address-forms/address-forms'
import { ConsentForm } from '../consent-form/consent-form'
import { DocumentForm } from '../document-from/document-form'
import { PersonalInfo } from '../personal-info-form/personal-info-form'

type Props = {
  entityId?: string
  getApplicantGeneralInfoPath: (applicantId: string) => string
  getApplicantPersonalInfoPath: (applicantId: string) => string
  restoreType?: 'edit' | 'resolve'
}

export function IndividualProfileF2({
  entityId,
  getApplicantGeneralInfoPath,
  getApplicantPersonalInfoPath,
  restoreType = 'edit',
}: Props) {
  const navigate = useNavigate()
  const [saveOnly, setSaveOnly] = useState(true)
  const t = useI18n(INDIVIDUAL_PROFILE_F2_KEY, { keys: individualProfileF2En })

  const isFrankie2R2 = useFrankie2R2Customer()
  const { data: entityData } = useEntityDataQuery(entityId)
  const { data: organizationSetting, isLoading: isOrgLoading } =
    useOrganisationSettingsQuery({
      canFetchOrganisationSettings: true,
    })

  const { data, isFetching: isEntityLoading } =
    useGetIndividualFormData(entityId)

  const isArchived =
    entityData?.serviceProfiles?.at(0)?.state === ServiceProfileState.ARCHIVED

  const { isOptionalAddress } = useHasFeatureFlag({
    isOptionalAddress: ['optionalFields', 'address'],
  })

  const form = useForm<IIndividualProfileF2FormType>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      nationality: CountryAlpha3CodeTypes.Australia,
      schemaVersion: 2,
      service: 'KYC',
      eKyc: true,
      entityType: 'INDIVIDUAL',
      emailAddresses: [
        {
          email: '',
          type: 'WORK',
        },
      ],
      phoneNumbers: [
        {
          type: 'MOBILE',
          country: CountryAlpha3CodeTypes.Australia,
          number: '',
        },
      ],
      documents: [{ country: CountryAlpha3CodeTypes.Australia }],
      addresses: [
        {
          country: CountryAlpha3CodeTypes.Australia,
          type: AddressCategoryTypes.current,
        },
      ],
      consents: [
        { type: ConsentType.CREDITHEADER },
        { type: ConsentType.DOCS },
        { type: ConsentType.GENERAL },
      ],
    },
  })

  const {
    reset,
    setValue,
    getValues,
    handleSubmit,
    control,
    formState: { errors },
  } = form

  const { xssRule } = useValidationRules()
  const { data: workflows } = useWorkflowListQuery()

  useEffect(() => {
    if (organizationSetting) {
      const values = getValues()
      const countrySetting = organizationSetting.find(
        setting => setting.name === 'country',
      )

      if (
        countrySetting?.value &&
        values.nationality !== countrySetting.value
      ) {
        setValue('nationality', countrySetting.value)

        setValue(
          'phoneNumbers',
          values.phoneNumbers?.map(phone => ({
            ...phone,
            country: countrySetting.value,
          })),
        )

        setValue(
          'documents',
          values.documents.map(doc => ({
            ...doc,
            country: countrySetting.value,
          })),
        )

        setValue(
          'addresses',
          values.addresses?.map(address => ({
            ...address,
            country: countrySetting.value,
          })),
        )
      }
    }
  }, [organizationSetting, getValues, setValue])

  useEffect(() => {
    if (data) {
      const updatedData = { ...data }
      updatedData.documents = data.documents.map(doc => ({
        ...doc,
        attachments: [
          doc.attachments?.find(i => i.side === 'FRONT') as IAttachment,
          doc.attachments?.find(i => i.side === 'BACK') as IAttachment,
        ],
      }))
      // reset with fresh data to avoid mutation from unregister
      reset(
        JSON.parse(JSON.stringify(updatedData)) as IIndividualProfileF2FormType,
      )
    }
  }, [data, reset])

  const profileOptions = workflows?.map(workflow => ({
    label: workflow.workflowName,
    value: workflow.workflowName,
  }))

  const {
    mutate,
    isLoading,
    isSuccess,
    data: createResponse,
  } = useCreateIndividual({
    saveOnly,
    onError: formData => {
      reset(formData, {
        keepDirtyValues: true,
        keepDirty: true,
      })
    },
  })

  const {
    mutate: mutateUpdate,
    isLoading: isUpdating,
    isSuccess: isSuccessUpdating,
  } = useUpdateIndividual(entityId, saveOnly)

  const handleForm = (formData: IIndividualProfileF2FormType) => {
    const filteredAddresses = formData.addresses?.map(address => {
      const { subdivision, ...addr } = address
      return {
        ...addr,
        ...(addr.country === CountryAlpha3CodeTypes.Australia
          ? { subdivision }
          : {}),
      }
    })

    const entityData = { ...formData, addresses: filteredAddresses }

    if (data) {
      mutateUpdate(entityData)
    } else {
      mutate(entityData)
    }
  }

  const { setOriginatedPath } = useSetOriginatedPath()

  const workflow = form.watch('workflow')
  const loadingOverall =
    isLoading || isUpdating || isEntityLoading || isOrgLoading

  const disableSave = !!Object.keys(errors).length || loadingOverall

  useEffect(() => {
    if (entityId) {
      if (restoreType === 'resolve')
        trackingManager.track(TrackingEventsTypes.EntityResolveShowIndividual)
      else
        trackingManager.track(
          TrackingEventsTypes.EntityViewShowEntityEditIndividual,
        )
    } else {
      trackingManager.track(TrackingEventsTypes.IndividualProfileCreateEntity)
    }
  }, [entityId, restoreType])

  useEffect(() => {
    const redirectEntityId = createResponse?.individual.entityId || entityId
    const isUnchecked =
      getWorkflowStatusKey(
        createResponse?.serviceProfiles.at(0)?.workflowSummaries?.at(0),
      ) === WorkflowStatusKeysTypes.UNCHECKED

    if ((isSuccess || isSuccessUpdating) && redirectEntityId) {
      const path = getApplicantGeneralInfoPath(redirectEntityId)
      const pathUnchecked = getApplicantPersonalInfoPath(redirectEntityId)

      if (isSuccess) setOriginatedPath(mainNavPaths.applicant)

      navigate(isUnchecked ? pathUnchecked : path)
    }
  }, [
    isSuccess,
    isSuccessUpdating,
    createResponse,
    entityId,
    getApplicantGeneralInfoPath,
    getApplicantPersonalInfoPath,
    navigate,
    setOriginatedPath,
  ])

  const formKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) =>
    e.key === 'Enter' && e.preventDefault()

  let loadingLabel: I18nKeys<typeof individualProfileF2En> = 'profileLoading'

  if (isEntityLoading) {
    loadingLabel = 'profileLoading'
  } else if (saveOnly) {
    loadingLabel = 'savingChanges'
  } else {
    loadingLabel = 'runningChecks'
  }

  return (
    <>
      <FrankieLoader
        fullscreen
        className="z-20 text-md text-tertiary-grey-800 font-semibold"
        size="md"
        loading={loadingOverall}
        label={t(loadingLabel)}
      />

      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <form
        autoComplete="off"
        className="pb-10"
        onKeyDown={formKeyDown}
        onSubmit={handleSubmit(handleForm)}
        data-qa={individualProfileF2Qa.form}
      >
        <div className="mx-auto">
          <div
            className="text-lg text-tertiary-grey-800 font-bold"
            data-qa={individualProfileF2Qa.workflow}
          >
            {t('workflow')}
          </div>
          <div className="flex relative">
            <SelectFormField
              className="mt-4 mb-2  basis-1/4"
              inputClassName="h-[37px]"
              control={control}
              name="workflow"
              placeholder="Select workflow"
              options={profileOptions || []}
              rules={{
                required: t('profileForm.errors.workflow'),
              }}
              showErrorText
              testId={{ input: individualProfileF2Qa.recipe }}
            />
          </div>
          {workflow && (
            <>
              <FrankieDivider className="my-8" />

              <PersonalInfo
                form={form}
                showOptionalFields={restoreType !== 'resolve'}
              />
              <FrankieDivider className="my-8" />

              <AddressForms
                form={form}
                isOptional={isOptionalAddress}
                country={
                  organizationSetting?.find(
                    setting => setting.name === 'country',
                  )?.value ?? CountryAlpha3CodeTypes.Australia
                }
              />

              <FrankieDivider className="my-8" />

              <DocumentForm form={form} isEdit={!!entityId} />

              <FrankieDivider className="my-8" />
              <ConsentForm form={form} />

              {isFrankie2R2 && (
                <TextAreaFormField
                  control={control}
                  className="mt-2"
                  name="comment.text"
                  placeholder={t('consentForm.addYourComment')}
                  label={t('consentForm.comment')}
                  rules={{
                    required: entityId && t('errors.comment'),
                    ...xssRule,
                  }}
                  trim
                  showError
                  counter={500}
                  maxLength={500}
                />
              )}

              <div className="flex gap-4 my-10 ">
                <div onMouseEnter={handleSubmit(() => {})}>
                  <FrankieButton
                    onClick={() => {
                      setSaveOnly(false)
                      if (entityId) {
                        if (restoreType === 'resolve') {
                          trackingManager.track(
                            TrackingEventsTypes.EntityResolveSaveVerifyIndividual,
                          )
                        } else {
                          trackingManager.track(
                            TrackingEventsTypes.EntityViewSaveVerifyIndividual,
                          )
                        }
                      } else {
                        trackingManager.track(
                          TrackingEventsTypes.IndividualProfileCreateSaveAndVerifyEntity,
                        )
                      }
                    }}
                    disabled={disableSave}
                    testId={{
                      button: individualProfileF2Qa.saveAndContinueButton,
                    }}
                    type="submit"
                    className="w-[120px] h-[40px]"
                  >
                    {t('saveVerify')}
                  </FrankieButton>
                </div>
                {restoreType !== 'resolve' && !isArchived && (
                  <div onMouseEnter={handleSubmit(() => {})}>
                    <FrankieButton
                      disabled={disableSave}
                      type="submit"
                      onClick={() => {
                        setSaveOnly(true)
                        if (entityId) {
                          trackingManager.track(
                            TrackingEventsTypes.EntityViewSaveIndividual,
                          )
                        } else {
                          trackingManager.track(
                            TrackingEventsTypes.IndividualProfileCreateSaveEntity,
                          )
                        }
                      }}
                      testId={{ button: individualProfileF2Qa.saveButton }}
                      className="w-[120px] h-[40px] text-primary-900"
                      intent="darkOutline"
                    >
                      {t('saveChanges')}
                    </FrankieButton>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </form>
    </>
  )
}
