import React, { useCallback, useEffect, useMemo, useState } from 'react'

import classNames from 'classnames'
import { UseFormReturn } from 'react-hook-form'

import {
  FrankieButton,
  FrankieCheckbox,
  FrankieLoader,
  FrankiePopover,
} from 'frankify/src'

import { defaultAddressFields } from 'features/individual-profile-f2/model/address.model'

import { AddressCategoryTypes } from 'entities/applicant'
import {
  CountryAlpha3CodeTypes,
  IStateOption,
  countryList,
  stateList,
} from 'entities/country'

import { SelectFormField, TextFormField } from 'shared/form'
import { usePrevious } from 'shared/hooks/model/previous-state'
import { I18nKeys, useI18n } from 'shared/i18n'

import { INDIVIDUAL_PROFILE_F2_KEY } from '../../../individual-profile-f2.key'
import { individualProfileF2En } from '../../../locale/individual-profile-f2.en'
import {
  Address,
  IIndividualProfileF2FormType,
} from '../../../model/individual-profile-f2-form.model'
import {
  singleAddressDataQa,
  singleAddressFormF2Qa,
} from '../../../qa/individual-profile-f2.qa'
import { useGetDetailAddress } from '../../../state/getDetailAddress.data'
import { useGetAddressSuggestion } from '../../../state/getSearchAddress.data'

type SingleAddressFormProps = {
  form: UseFormReturn<IIndividualProfileF2FormType>
  idx: number
  isOptional?: boolean
}

type SplingSplitterProps = {
  inputString: string
  subString: string
}

type Field = {
  name: keyof Address
  className?: string
  optional?: boolean
  isSelect?: true
  options?: IStateOption[]
  helperTextClassName?: string
  placeholder?: I18nKeys<typeof individualProfileF2En>
  error?: I18nKeys<typeof individualProfileF2En>
  label?: keyof Address
}

/**
 * This function filters out the 'subdivision' field if the country is 'NZL'.
 * All other fields are included by default.
 */
function filterFields(fieldMap: Field[], country: string): Field[] {
  const filtered = fieldMap
    .filter(field => field.name !== 'subdivision' || country !== 'NZL')
    .map(field => {
      if (field.name === 'locality' && country === 'NZL') {
        return {
          ...field,
          name: 'locality' as keyof Address,
          label: 'localityNZL' as keyof Address,
          error: 'addressForm.localityNZLError' as I18nKeys<
            typeof individualProfileF2En
          >,
        }
      }

      return field
    })

  if (country === 'NZL') {
    filtered.splice(
      filtered.findIndex(field => field.name === 'streetName') + 1,
      0,
      {
        name: 'neighborhood' as keyof Address,
        label: 'subrubOptional' as keyof Address,
        className: 'col-span-2 row-start-3',
        optional: true,
      },
    )
  }

  return filtered
}

function StringSplitter({ inputString, subString }: SplingSplitterProps) {
  const trimmed = subString.trim()
  const index = inputString.toLowerCase().indexOf(trimmed.toLowerCase())

  // If the substring is not found, return original string as an array
  if (index === -1) {
    return [inputString]
  }

  // Check if the substring has a space before and after
  const haveLeft = inputString.at(index) === ' '
  const haveRight = inputString.at(index + trimmed.length) === ' '

  return (
    <>
      {inputString.substring(0, index)}
      <b className={classNames(haveRight && 'mr-1', haveLeft && 'ml-1')}>
        {inputString.substring(index, index + trimmed.length)}
      </b>
      {inputString.substring(index + trimmed.length)}
    </>
  )
}

export function SingleAddressForm({
  form,
  idx,
  isOptional,
}: SingleAddressFormProps) {
  const t = useI18n(INDIVIDUAL_PROFILE_F2_KEY, { keys: individualProfileF2En })
  const [openAddressSuggestion, setOpenAddressSuggestion] = useState(false)
  const [selectedGoogleAddressId, setSelectedGoogleAddressId] = useState('')
  const prevSelectedGoogleAddressId = usePrevious(selectedGoogleAddressId)

  const [showManualAddress, setShowManualAddress] = useState<boolean | null>(
    null,
  )

  const { control, resetField, watch, setValue, getValues, setFocus, trigger } =
    form

  const getFieldName = useCallback(
    (name: keyof Address) =>
      `addresses.${idx}.${name}` as keyof IIndividualProfileF2FormType,
    [idx],
  )

  const sameAsCurrent = watch('sameAsCurrent')
  const country = watch(getFieldName('country')) as string
  const searchTerm = watch(getFieldName('unstructuredLongForm')) as string

  const addressType = (watch(getFieldName('type')) ||
    AddressCategoryTypes.current) as AddressCategoryTypes

  const showSameAs = addressType === AddressCategoryTypes.mailing
  const prevCountry = usePrevious(country)

  const countryOptions = useMemo(
    () =>
      countryList
        .filter(country =>
          [
            CountryAlpha3CodeTypes.Australia,
            CountryAlpha3CodeTypes.NEW_ZEALAND,
          ].includes(country.alpha3code as CountryAlpha3CodeTypes),
        )
        .map(country => ({
          label: country.name,
          value: country.alpha3code,
        })),
    [],
  )

  const fieldMap: Field[] = filterFields(
    [
      {
        name: 'buildingName',
        className: 'col-span-2 row-start-2',
        optional: true,
      },
      {
        name: 'unitNumber',
        optional: true,
        className: 'row-start-3',
        placeholder: 'addressForm.unitNumberPlaceholder',
      },
      {
        name: 'streetNumber',
        className: 'col-span-1 row-start-3',
        error: 'addressForm.streetNumberError',
        placeholder: 'addressForm.streetNumberPlaceholder',
      },
      {
        name: 'streetName',
        className: 'col-span-2 row-start-3',
        error: 'addressForm.streetNameError',
        placeholder: 'addressForm.streetNamePlaceholder',
      },
      {
        name: 'locality',
        className: 'col-span-2 row-start-4',
        error: 'addressForm.suburbError',
      },
      {
        name: 'subdivision',
        isSelect: true,
        className: 'row-start-4',
        options: stateList.AUS,
        error: 'addressForm.stateError',
      },
      {
        name: 'postalCode',
        className: 'row-start-4',
        error: 'addressForm.postalCodeError',
      },
    ],
    country,
  )

  const { data: detailAddress = undefined, refetch } = useGetDetailAddress(
    selectedGoogleAddressId,
  )

  const { data: placesData = [], isFetching } = useGetAddressSuggestion(
    searchTerm,
    [country] as string[],
  )

  useEffect(() => {
    if (!detailAddress) {
      return
    }

    delete (detailAddress as unknown as { data?: object }).data

    Object.entries(detailAddress).forEach(([key, value]) => {
      if (Object.hasOwn(detailAddress, key)) {
        const fieldName = getFieldName(key as keyof typeof detailAddress)

        // if defaultAddressfields is `null` or `undefined`, then look for the value in the defaultAddressFields.
        // if the key is available, then set the defaultAddressFields[key] in the form, otherwise set value that is coming from API.
        const addressValue =
          value ||
          (Object.hasOwn(defaultAddressFields, key)
            ? defaultAddressFields[key as keyof typeof defaultAddressFields]
            : value)

        if (addressValue || !getValues(fieldName)) {
          setValue(fieldName, addressValue)
        }
      }
    })

    setOpenAddressSuggestion(false)
    setSelectedGoogleAddressId('')
  }, [detailAddress, getFieldName, getValues, setValue])

  const getRequiredRule = ({
    label,
    isSelect,
    error,
  }: {
    label: I18nKeys<typeof individualProfileF2En>
    isSelect?: boolean
    error?: I18nKeys<typeof individualProfileF2En>
  }) =>
    isOptional
      ? {}
      : {
          required: error
            ? t(error)
            : t(isSelect ? 'errors.select' : 'errors.requiredEntity', {
                label: t(label).toLowerCase(),
              }),
        }

  useEffect(() => {
    const addressId = getValues(getFieldName('addressId'))
    const unstructuredLongForm = getValues(getFieldName('unstructuredLongForm'))
    const type = getValues(getFieldName('type'))

    if (addressId && !unstructuredLongForm) {
      for (const key in defaultAddressFields) {
        if (getValues(getFieldName(key as keyof Address))) {
          setShowManualAddress(true)
          return
        }
      }
    } else if (type !== AddressCategoryTypes.current) {
      setFocus(getFieldName('unstructuredLongForm'))
    }
  }, [getFieldName, getValues, setFocus])

  /**
   * Refetches the address suggestion data when the selected Google address ID changes.
   * Address ID is set when a user selects an address from the Google Places suggestions.
   */
  useEffect(() => {
    if (selectedGoogleAddressId !== prevSelectedGoogleAddressId) {
      void refetch()
    }
  }, [refetch, selectedGoogleAddressId, prevSelectedGoogleAddressId])

  /**
   * Reset the all address fields when the country changes.
   */
  useEffect(() => {
    if (prevCountry && country !== prevCountry) {
      setValue(`addresses.${idx}`, {
        ...getValues(`addresses.${idx}`),
        ...defaultAddressFields,
      })
    }
  }, [idx, country, prevCountry, setValue, getValues])

  /**
   * Handles the selection of an address from Google Places suggestions.
   *
   * @param {string} googleAddressId - The unique identifier for the selected Google address.
   *
   * This function performs the following actions:
   * 1. Closes the address suggestion dropdown.
   * 2. Sets the selected Google address ID.
   * 3. Updates the form field with the unstructured long form address corresponding to the selected Google address ID.
   * 4. Blurs the input field to remove focus.
   */
  const handleSelectedAddress = (googleAddressId: string) => {
    setOpenAddressSuggestion(false)
    setSelectedGoogleAddressId(googleAddressId)
    setValue(
      getFieldName('unstructuredLongForm'),
      placesData.find(i => i.value === googleAddressId)?.label,
    )

    const [ref] = document.getElementsByName(
      getFieldName('unstructuredLongForm'),
    )

    setTimeout(() => {
      ref.blur()
    }, 0)
  }

  /**
   * Handles the UI change for the address form.
   *
   * This function updates the state to show or hide the manual address input,
   * resets the 'sameAsCurrent' field, updates the address fields with default values,
   * and sets the focus on the appropriate input field based on the `showManualInput` flag.
   *
   * @param showManualInput - A boolean indicating whether to show the manual address input.
   */
  const handleUiChange = (showManualInput: boolean) => {
    setShowManualAddress(showManualInput)
    setValue('sameAsCurrent', false)
    setValue(`addresses.${idx}`, {
      ...getValues(`addresses.${idx}`),
      ...defaultAddressFields,
    })
    if (showManualInput) {
      setFocus(getFieldName('buildingName'))
    } else {
      setFocus(getFieldName('unstructuredLongForm'), { shouldSelect: true })
    }
  }

  /**
   * Handles the change event for a checkbox.
   *
   * This function is triggered when the checkbox state changes. It performs the following actions:
   * 1. Triggers validation for the first address field and focuses on it if validation fails.
   * 2. Resets the field at the specified index with default values for country and address type.
   * 3. Toggles the `sameAsCurrent` state.
   *
   * @async
   * @function handleCheckboxChange
   * @returns {Promise<void>} A promise that resolves when the checkbox change handling is complete.
   */
  const handleCheckboxChange = useCallback(async () => {
    await trigger('addresses.0', { shouldFocus: true })
    resetField(`addresses.${idx}`, {
      defaultValue: { country, type: addressType },
    })
    setValue('sameAsCurrent', !sameAsCurrent)
  }, [idx, addressType, country, sameAsCurrent, resetField, setValue, trigger])

  if (sameAsCurrent && showSameAs) {
    return (
      <div
        className="grid grid-cols-4 gap-x-6 gap-y-4"
        data-qa={singleAddressFormF2Qa.wrapper}
      >
        <div className="flex col-span-4 gap-2 items-center pt-6">
          <FrankieCheckbox
            checked={!!sameAsCurrent}
            onChange={handleCheckboxChange}
          />
          <span>{t('addressForm.sameAsCurrent')}</span>
        </div>
      </div>
    )
  }

  return (
    <div
      className="grid grid-cols-6 gap-x-6 gap-y-4"
      data-qa={singleAddressFormF2Qa.wrapper}
    >
      {showSameAs && (
        <div className="flex col-span-4 gap-2 items-center pt-6">
          <FrankieCheckbox
            checked={!!sameAsCurrent}
            onChange={handleCheckboxChange}
          />
          <span>{t('addressForm.sameAsCurrent')}</span>
        </div>
      )}

      <div className="col-span-2 row-start-1">
        <SelectFormField
          control={control}
          options={countryOptions}
          name={getFieldName('country')}
          label={t('addressForm.country')}
          testId={{ input: singleAddressFormF2Qa.country }}
        />

        {showManualAddress ? (
          <p className="font-sm text-tertiary-grey-500 mt-2">
            {t('cannotBePOBox')}
          </p>
        ) : null}
      </div>

      {!showManualAddress ? (
        <div className="col-span-4 row-start-2 !w-[50vw] max-w-[645px] -mt-4 relative bg-white-100">
          <FrankiePopover
            open={openAddressSuggestion}
            onOpenChange={open => {
              if (!selectedGoogleAddressId)
                setValue(getFieldName('unstructuredLongForm'), '')
              setOpenAddressSuggestion(open)
            }}
            popoverRest={{ placement: 'bottom-start' }}
            initialFocus={-1}
            trigger={
              <div>
                <TextFormField
                  trim
                  data-hj-suppress
                  testId={{ input: singleAddressFormF2Qa.unstructuredLongForm }}
                  control={control}
                  name={getFieldName('unstructuredLongForm')}
                  type="search"
                  closeButton={{
                    onClick: () => {
                      setSelectedGoogleAddressId('')
                      setValue(`addresses.${idx}`, {
                        ...getValues(`addresses.${idx}`),
                        ...defaultAddressFields,
                      })
                      setOpenAddressSuggestion(false)
                    },
                  }}
                  autoComplete="off"
                  className="mt-4 mb-4"
                  onClick={() => {
                    setOpenAddressSuggestion(true)
                  }}
                  label=""
                  rules={{
                    required:
                      !isOptional &&
                      t('errors.requiredEntity', {
                        label: t(`addressForm.${addressType}`),
                      }),
                  }}
                  isSearchIcon
                  showErrorText
                  searchIconPlacement="end"
                  placeholder={t('profileForm.searchAddress')}
                />
              </div>
            }
          >
            <div className="justify-start !w-[50vw] max-w-[645px] bg-surface shadow-md rounded-sm">
              <ul>
                {placesData.length ? (
                  placesData.map(item => (
                    <li
                      role="option"
                      aria-selected
                      tabIndex={-1}
                      id={item.label}
                      data-hj-suppress
                    >
                      <FrankieButton
                        className="py-2 px-2 h-[48px] w-full inline-block text-start cursor-pointer hover:bg-primary-50 whitespace-nowrap overflow-hidden text-ellipsis"
                        noStyles
                        key={item.value}
                        onClick={() => handleSelectedAddress(item.value)}
                        testId={{ button: singleAddressFormF2Qa.placesData }}
                      >
                        <StringSplitter
                          inputString={item.label}
                          subString={searchTerm}
                        />
                      </FrankieButton>
                    </li>
                  ))
                ) : (
                  <li
                    role="option"
                    aria-selected
                    tabIndex={-1}
                    className="py-2 px-2 h-[48px] flex items-center"
                    data-qa={singleAddressFormF2Qa.noSearchResults}
                  >
                    {t('noSearchResults')}
                    {isFetching && (
                      <FrankieLoader
                        loading
                        size="xs"
                        className="ml-auto mr-5 w-min"
                      />
                    )}
                  </li>
                )}
                <li
                  tabIndex={-1}
                  className="py-1 px-2 h-[48px] flex items-center"
                >
                  <FrankieButton
                    role="option"
                    className="text-primary-900 "
                    noStyles
                    onClick={() => handleUiChange(true)}
                    testId={{
                      button: singleAddressFormF2Qa.enterAddressManually,
                    }}
                  >
                    {t('addressForm.enterAddressManually')}
                  </FrankieButton>
                </li>
              </ul>
            </div>
          </FrankiePopover>
          <p className="font-sm text-tertiary-grey-500 -mt-3 ">
            {t('cannotBePOBox')}
          </p>
        </div>
      ) : (
        <>
          {fieldMap.map(field =>
            field.isSelect ? (
              <SelectFormField
                showErrorText
                key={field.name}
                options={field.options || []}
                label={t(`addressForm.${field.name}`)}
                name={getFieldName(field.name)}
                className={field.className}
                control={control}
                helperTextClassName={field.helperTextClassName}
                testId={{ input: singleAddressDataQa(field.name) }}
                rules={
                  field.optional
                    ? {}
                    : getRequiredRule({
                        error: field.error,
                        label: `addressForm.${field.name}`,
                        isSelect: true,
                      })
                }
              />
            ) : (
              <TextFormField
                trim
                key={field.name}
                label={t(`addressForm.${field.label || field.name}`)}
                placeholder={field.placeholder && t(field.placeholder)}
                name={getFieldName(field.name)}
                className={field.className}
                helperTextClassName={field.helperTextClassName}
                control={control}
                rules={
                  field.optional
                    ? {}
                    : getRequiredRule({
                        error: field.error,
                        label: `addressForm.${field.name}`,
                      })
                }
                showErrorText
                testId={{ input: singleAddressDataQa(field.name) }}
              />
            ),
          )}
          <div className="col-span-4 row-start-5">
            <FrankieButton
              noStyles
              className="text-primary-900"
              onClick={() => handleUiChange(false)}
              testId={{ button: singleAddressFormF2Qa.useAddressSearch }}
            >
              {t('addressForm.useAddressSearch')}
            </FrankieButton>
          </div>
        </>
      )}
    </div>
  )
}
