import { Nullable, PartialRecord } from 'shared/typescript'

export type ShareholdingOwnership = {
  sharesHeld: Nullable<number>
  isBeneficiallyHeld: Nullable<boolean>
  jointHolding: Nullable<boolean>
  shareClass: Nullable<string>
  fullyPaid: Nullable<boolean>
}

export type OwnershipDetails = {
  total: Nullable<number>
  beneficiallyHeld: Nullable<number>
  nonBeneficiallyHeld: Nullable<number>
  shareholdings: ShareholdingOwnership[]
}

type AmlCriteria = {
  checkTypes: string[]
  name: {
    given: string
    middle: string
    family: string
  }
}

type AmlStatusReader = {
  results: unknown
}

type AmlDetails = {
  results: unknown
  criteria: AmlCriteria
  amlStatusReader: AmlStatusReader
}

type KycStatus = {
  code: unknown
}

type RiskLevel = {
  term: string
  label: string
  key: string
}

type KycDetails = {
  status: KycStatus
  sources: unknown[]
  issuesList: ({ term: string } | string)[]
  riskLevel: RiskLevel
}

export type OfficeHolder = {
  entityId: string
  name: string
  asicExtract?: { companyType?: string; acn?: string }
  abrExtract?: { entityType?: string; abn?: string }
  ownership: PartialRecord<string, OwnershipDetails>
  aml: AmlDetails
  kyc: KycDetails
  addressLongForm: string
  dateOfBirth: string
  placeOfBirth: string
  type: Nullable<string>
  entityType?: Nullable<'ORGANISATION' | 'INDIVIDUAL'>
  startDate: Nullable<string>
  profileType?: Nullable<string>
  customerReference?: Nullable<string>
  phoneNumber: string
  emailAddress: string
  originalDetails?: {
    name: Nullable<string>
  }
  reasons?: string[]
  percentageOwned?: {
    beneficially: Nullable<number>
    nonBeneficially: Nullable<number>
    total: Nullable<number>
  }
  percentageHolding?: {
    beneficially: Nullable<number>
    nonBeneficially: Nullable<number>
    jointly: Nullable<number>
    total: Nullable<number>
  }
  shareholdings?: ShareholdingOwnership[]
  historicalStatus: Nullable<string>
  ceasedDate: Nullable<string>
  roles?: {
    type: string
    typeDescription: string
  }[]
}

export type OwnershipPolicy = {
  blockingDefinitions?: string[]
  blockingThreshold?: number
  uboThreshold?: number
}

export type ShareCapitalGroup = {
  shareCapital?: {
    classCode?: string
    classTitle?: string
    numberOfShares?: number
    totalAmountPaid?: number
    totalAmountDueAndPayable?: number
  }[]
  totalNumberOfShares?: number
}

type ApplicantOwnershipRecordTypes = {
  reportCreatedAt: string
  ubos: OfficeHolder[]
  beneficialOwners: OfficeHolder[]
  nonIndividuals: OfficeHolder[]
  officeHolders: OfficeHolder[]
  shareCapitalGroup?: ShareCapitalGroup
  shareHolders: OfficeHolder[]
  linkedCompanies: OfficeHolder[]
  associatedParties: OfficeHolder[]
  blockingEntities: OfficeHolder[]
}

export type ApplicantOwnershipResponse = {
  ownershipPolicy?: OwnershipPolicy
} & ApplicantOwnershipRecordTypes

export type ApplicantOwnershipHolderTypes = keyof Omit<
  ApplicantOwnershipRecordTypes,
  'reportCreatedAt'
>

export type ApplicantOwnershipHolderRecord = {
  id: string
  entityId: string
  type: 'individual' | 'organisation'
  name: string
  originalName: string
  customerReference: string
  beneficiallyHeldOwnership: string
  nonBeneficiallyHeldOwnership: string
  entityType: string
  role: string
  currentPrevious: string
  jointHolding: string
  sharesHeld: string
  sharesIssued: string
  totalAmountPaid: string
  totalAmountDueAndPayable: string
  beneficiallyHeld: string
  classTitle: string
  classCode: string
  total: string
  profileStatus: string
  issues: string[]
  roleType: string
  recipe: string
  riskLevel: string
  roles: string[]
  otherRoleTypeDescription?: string
  actions?: ('sendKycLink' | 'editParty' | 'removeParty')[]
  reasons: string
}

export const applicantOwnershipHolderRecordConfig: Omit<
  ApplicantOwnershipHolderRecord,
  'id'
> = {
  beneficiallyHeld: '',
  beneficiallyHeldOwnership: '',
  classCode: '',
  classTitle: '',
  currentPrevious: '',
  customerReference: '',
  entityId: '',
  entityType: '',
  issues: [],
  jointHolding: '',
  name: '',
  nonBeneficiallyHeldOwnership: '',
  originalName: '',
  profileStatus: '',
  recipe: '',
  riskLevel: '',
  role: '',
  roleType: '',
  sharesHeld: '',
  sharesIssued: '',
  total: '',
  totalAmountDueAndPayable: '',
  totalAmountPaid: '',
  type: 'individual',
  roles: [],
  actions: [],
  reasons: '',
}

export type ApplicantOwnershipHolderRecordProperties =
  keyof ApplicantOwnershipHolderRecord

export type IssuedShareCapitalData = {
  classTitle: string
  classCode: string
  sharesIssued: string
  totalAmountPaid: string
  totalAmountDueAndPayable: string
}

/**
 * @todo Remove dummy data
 */
export const dummyOwnershipData: ApplicantOwnershipResponse = {
  reportCreatedAt: '2024-02-27T14:20:00.695Z',
  ubos: [
    {
      entityId: '40fcac8f-bb1a-fc34-402e-374a925e7a6a',
      name: 'ELIZABETH SEON POWIS',
      ownership: {
        '587a49a3-6246-5a93-c36c-6c13963394a3': {
          total: 100,
          beneficiallyHeld: 100,
          nonBeneficiallyHeld: 0,
          shareholdings: [
            {
              isBeneficiallyHeld: true,
              jointHolding: false,
              shareClass: 'OWNER',
              sharesHeld: 1,
              fullyPaid: null,
            },
          ],
        },
      },
      aml: {
        results: null,
        criteria: {
          checkTypes: ['m'],
          name: {
            given: 'ELIZABETH',
            middle: 'SEON',
            family: 'POWIS',
          },
        },
        amlStatusReader: {
          results: null,
        },
      },
      kyc: {
        status: {
          code: null,
        },
        sources: [],
        issuesList: ['device', 'aml', 'pep'],
        riskLevel: {
          term: 'risk_unknown',
          label: 'Unknown',
          key: '',
        },
      },
      addressLongForm: '',
      dateOfBirth: '',
      placeOfBirth: '',
      type: null,
      startDate: null,
      profileType: '',
      customerReference: '',
      phoneNumber: '',
      emailAddress: '',
      originalDetails: {
        name: null,
      },
      historicalStatus: null,
      ceasedDate: null,
    },
  ],
  shareCapitalGroup: {
    shareCapital: [
      {
        classCode: 'A',
        classTitle: 'Common Shares',
        numberOfShares: 1000,
        totalAmountPaid: 5000,
        totalAmountDueAndPayable: 0,
      },
      {
        classCode: 'B',
        classTitle: 'Preferred Shares',
        numberOfShares: 500,
        totalAmountPaid: 7500,
        totalAmountDueAndPayable: 2500,
      },
      // Add more share classes as needed
    ],
    totalNumberOfShares: 1500,
  },
  beneficialOwners: [],
  nonIndividuals: [],
  blockingEntities: [],
  officeHolders: [
    {
      entityId: '40fcac8f-bb1a-fc34-402e-374a925e7a6a',
      name: 'ELIZABETH SEON POWIS',
      ownership: {
        '587a49a3-6246-5a93-c36c-6c13963394a3': {
          total: 100,
          beneficiallyHeld: 100,
          nonBeneficiallyHeld: 0,
          shareholdings: [
            {
              isBeneficiallyHeld: true,
              jointHolding: false,
              shareClass: 'OWNER',
              sharesHeld: 1,
              fullyPaid: null,
            },
          ],
        },
      },
      aml: {
        results: null,
        criteria: {
          checkTypes: ['m'],
          name: {
            given: 'ELIZABETH',
            middle: 'SEON',
            family: 'POWIS',
          },
        },
        amlStatusReader: {
          results: null,
        },
      },
      kyc: {
        status: {
          code: null,
        },
        sources: [],
        issuesList: ['device', 'aml', 'pep'],
        riskLevel: {
          term: 'risk_unknown',
          label: 'Unknown',
          key: '',
        },
      },
      addressLongForm: '',
      dateOfBirth: '',
      placeOfBirth: '',
      type: 'OWN',
      startDate: '',
      profileType: '',
      customerReference: '',
      phoneNumber: '',
      emailAddress: '',
      originalDetails: {
        name: null,
      },
      historicalStatus: '-',
      ceasedDate: '',
    },
  ],
  shareHolders: [
    {
      entityId: '40fcac8f-bb1a-fc34-402e-374a925e7a6a',
      name: 'ELIZABETH SEON POWIS',
      ownership: {
        '587a49a3-6246-5a93-c36c-6c13963394a3': {
          total: 100,
          beneficiallyHeld: 100,
          nonBeneficiallyHeld: 0,
          shareholdings: [
            {
              isBeneficiallyHeld: true,
              jointHolding: false,
              shareClass: 'OWNER',
              sharesHeld: 1,
              fullyPaid: null,
            },
          ],
        },
      },
      aml: {
        results: null,
        criteria: {
          checkTypes: ['m'],
          name: {
            given: 'ELIZABETH',
            middle: 'SEON',
            family: 'POWIS',
          },
        },
        amlStatusReader: {
          results: null,
        },
      },
      kyc: {
        status: {
          code: null,
        },
        sources: [],
        issuesList: ['device', 'aml', 'pep'],
        riskLevel: {
          term: 'risk_unknown',
          label: 'Unknown',
          key: '',
        },
      },
      addressLongForm: '',
      dateOfBirth: '',
      placeOfBirth: '',
      type: null,
      startDate: null,
      profileType: '',
      customerReference: '',
      phoneNumber: '',
      emailAddress: '',
      originalDetails: {
        name: null,
      },
      historicalStatus: null,
      ceasedDate: null,
    },
  ],
  linkedCompanies: [],
  associatedParties: [
    {
      roles: [
        {
          type: 'SCR',
          typeDescription: 'Secretary',
        },
        {
          type: 'TRST',
          typeDescription: 'Trustee',
        },
      ],
      entityId: '8fbb8e04-f3d8-0631-9a9e-fb5b3aa3e330',
      name: 'Tone  Bue',
      ownership: {},
      aml: {
        results: null,
        criteria: {
          checkTypes: ['m'],
          name: {
            given: 'Tone',
            middle: '',
            family: 'Bue',
          },
        },
        amlStatusReader: {
          results: null,
        },
      },
      kyc: {
        status: {
          code: null,
        },
        sources: [],
        issuesList: ['pep'],
        riskLevel: {
          term: 'risk_unknown',
          label: 'Unknown',
          key: '',
        },
      },
      addressLongForm:
        '27 / BLOCK 37 TO, STREET, YIO CHU KANG SERENITY PARK 807153, Singapore',
      dateOfBirth: '1989-10-16',
      placeOfBirth: '',
      type: null,
      startDate: null,
      profileType: '',
      customerReference: '',
      phoneNumber: '',
      emailAddress: '',
      historicalStatus: null,
      ceasedDate: null,
    },
  ],
}
